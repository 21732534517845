



























































import { Navigation } from "@/mixins/navigation";
import { Category as CategoryInterface } from "@/models/category.interface";
import { Notification } from "@/models/notification.interface";
import Component, { mixins } from "vue-class-component";
import { VueEditor } from "vue2-editor";
import Category from "@/components/Layout/Common/Category.vue";

@Component({
  components: {
    VueEditor,
    Category,
  },
})
export default class Home extends mixins(Navigation) {
  categories: { all: CategoryInterface[]; tree: CategoryInterface[] } = {
    all: [],
    tree: [],
  };

  private async created() {
    window.scrollTo(0, 0);
    this.categories = await this.$store
      .dispatch("categories/getAssignableCategories")
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Categories.fetchError.get"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private goToCategory(category: CategoryInterface) {
    this.navigate("/catalogue/category/" + category.id + "/" + 1);
  }

  private get carousel(): string {
    return this.$store.getters["system/getCarousel"];
  }

  private get description(): string {
    let company = this.$store.getters["system/getCompany"];
    if (company) {
      return company.description;
    } else {
      return "";
    }
  }
  private get categoriesCount() {
    return this.categories.all.length;
  }
}
